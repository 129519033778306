import React, {FC} from 'react';
import {CardTemplate} from "./CardTemplate";

export const EndangeredSpeciesContent: FC<{}> = ({}) => {
    return (
        <CardTemplate
            title={"Endangered species detection"}
            imgSrc={"/img/cards/species.png"}
            iconSrc={"/img/icons/cam.svg"}
            categoryTag={"Canis lupus"}
            tagColor={"#3D8283"}
            timestamp={"2022-05-29T20:14:38Z"}
        />
    )
}
