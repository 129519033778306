import React, {FC, ReactNode} from 'react';
import {Popper} from "@mui/material";

export const CardPopper: FC<{
    card: ReactNode,
    anchorEl: null | HTMLElement,
    isMobile?: boolean,
}> = ({anchorEl, isMobile, card}) => {
    const isOpen = Boolean(anchorEl);
    return (
        <Popper open={isOpen} anchorEl={anchorEl} placement={isMobile ? 'top' : 'left-end'} style={{zIndex: 14}}>
            {card}
        </Popper>
    )
}
