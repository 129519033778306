import React, {FC, PropsWithChildren} from 'react';
import {Box, Card, CardContent, CardMedia, Icon, Stack, styled, SvgIcon, Typography} from "@mui/material";

const CardContentNoPadding = styled(CardContent)(`
  &:last-child {
    padding-bottom:8px;
  }
`);

export const CardTemplate: FC<{
    imgSrc: string,
    iconSrc: string,
    title: string,
    categoryTag: string,
    tagColor: string,
    timestamp: string,
}> = ({imgSrc, iconSrc, title, categoryTag, timestamp, tagColor}) => {
    return (
        <Card sx={{maxWidth: 300, minWidth: 300, borderRadius: 1, mr: 1}} elevation={5}>
            <CardMedia
                component="img"
                height="180"
                image={imgSrc}
                alt={title}
            />
            <CardContentNoPadding sx={{p: 1, pb: 1}}>
                <Stack direction={'row'} spacing={1} alignItems={'center'}>
                    <Box sx={{width: 40, textAlign: 'center'}}>

                    <img src={iconSrc} alt={title} style={{width: 30}}/>
                    </Box>
                    <Box>
                        <Typography sx={{fontSize: 10}}>{title}</Typography>
                        <Typography sx={{fontSize: 12, fontWeight: 800, color: tagColor}}>{categoryTag}</Typography>
                        <Typography sx={{fontSize: 8}}>{timestamp}</Typography>
                    </Box>
                </Stack>
            </CardContentNoPadding>
        </Card>
    )
}
