import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {LandingPage} from './landing';
import reportWebVitals from './reportWebVitals';
import {createTheme, ThemeProvider} from "@mui/material";
import {blueGrey, cyan, grey, teal} from "@mui/material/colors";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
const theme = createTheme({
    palette:{
        primary: grey,
        secondary: blueGrey
    },
    typography: {
        h1: {
            fontSize: 22,
            fontWeight: 800,
            color: '#444'
        },
        h3: {
            fontSize: 18,
            fontWeight: 800,
            color: '#222'
        },
        body1: {
            fontSize: 12,
            fontWeight: 400,
            color: '#333'
        },
    }
});


root.render(
  <React.StrictMode>
      <ThemeProvider theme={theme}>
    <LandingPage />
      </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
