import React, {FC, PropsWithChildren, ReactNode} from 'react';
import {Box, Container, Grid, Stack, Typography, useMediaQuery} from "@mui/material";
import {WatchtowerIcon} from "../../comp/WatchtowerIcon";
import {WatchtowerFeatureSection} from "../../comp/WatchtowerFeatureSection";
import {WatchtowerFeatureText} from "../../comp/WatchtowerFeatureText";

const gridColor = false

const devSectionData = {
    title: "Developer Friendly",
    description: "Composable data streams make it easy for your team to quickly glean important wildlife insights," +
        " instead of spending hours sifting through a sea of noisy data.",
    imgSrc: "/img/developer_graphic@2x.png",
}

const dataStreamsData = {
    title: "Clear signals",
    description: "Composable data streams make it easy for your team to quickly glean important wildlife insights," +
        " instead of spending hours sifting through a sea of noisy data.",
    imgSrc: "/img/streams_graphic@2x.png",
}

const eventMapSectionData = {
    title: "Realtime wildlife event map",
    description: "Make critical decisions and take quick action to preserve wildlife using realtime data from" +
        " ranger reports, community reports, cameras, drones data, and various other realtime sensors" +
        " in the field.",
    imgSrc: "/img/ws_topo_dashboard_rd_preview.jpg",
}

export function WatchtowerSection() {

    const isDesktop = useMediaQuery('(min-width:1024px)');
    return (


        <Container sx={{mb: 12, position: 'relative', top: 0}}>
            <Box sx={{textAlign: 'center', my: 6}}>
                <WatchtowerIcon sx={{fontSize: 120, color: '#444'}}/>
                <Typography variant={'h1'} sx={{mt: 1, letterSpacing: 1}}>WATCHTOWER</Typography>
            </Box>
            <EventMap/>
            <DataStreams/>
            <DevFriendly/>
        </Container>
    );
}

export const IpadContainer: FC<PropsWithChildren<{ width: number }>> = ({children, width}) => {
    return (
        <div style={{
            borderRadius: 20,
            width: width,
            background: '#424141',
            boxShadow: '50px 50px 50px rgba(0, 0, 0, 0.45)',
            zIndex: 10,
            position: 'relative',
            marginBottom: 25,
        }}>
            {children}
        </div>
    );
}


export const EventMap: FC<{
    // isTablet: boolean,
    // isDesktop: boolean,
}> = ({}) => {
    const isDesktop = useMediaQuery('(min-width:1024px)');
    const isTablet = useMediaQuery('(min-width:480px)');
    const {title, description, imgSrc} = eventMapSectionData
    const centeredText = !isDesktop && !isTablet
    return (
        <Grid
            container
            sx={{minHeight: isDesktop ? 500 : 300, background: gridColor ? 'yellow' : ''}}
            direction={'row-reverse'}
            alignItems={'center'}
            justifyContent={'center'}
            spacing={3}
        >
            <Grid item xs={12} sm={7}>
                <Box
                    sx={{background: gridColor ? 'blue' : '', display: 'flex', mt: 0, pt: 0, pb: 3}}
                    justifyContent={'flex-start'}
                    alignItems={'center'}
                >
                    <IpadContainer width={536}>
                        <img src={imgSrc} alt="dashboard" width={512}
                             style={{margin: 12, borderRadius: 3}}/>
                    </IpadContainer>
                </Box>

            </Grid>
            <Grid item xs={12} sm={5}>
                <Box
                    sx={{background: gridColor ? 'red' : '', display: 'flex', pb: 3}}
                    justifyContent={centeredText ? 'center' : 'flex-end'}
                    alignItems={'center'}
                >
                    <WatchtowerFeatureText
                        title={title}
                        description={description}
                        textAlign={centeredText ? 'center' : 'right'}
                    />
                </Box>
            </Grid>
        </Grid>
    );
}


export const DataStreams: FC<{
    // isTablet: boolean,
    // isDesktop: boolean,
}> = ({}) => {
    const isDesktop = useMediaQuery('(min-width:1024px)');
    const isTablet = useMediaQuery('(min-width:480px)');
    const {title, description, imgSrc} = dataStreamsData
    const isMobile = !isDesktop && !isTablet
    return (
        <Grid
            container
            sx={{minHeight: isDesktop ? 500 : 300, background: gridColor ? 'yellow' : '', mt: isMobile ? 9 :0}}
            direction={'row'}
            alignItems={'center'}
            justifyContent={'center'}
            spacing={!isMobile ? 3 : 0}
        >
            <Grid item xs={12} sm={7} md={7}>
                <Box
                    sx={{background: gridColor ? 'red' : '', display: 'flex', pb: !isMobile ? 3 : 0}}
                    justifyContent={isMobile ? 'center' : 'flex-end'}
                    alignItems={'center'}
                >
                    <img src={imgSrc} alt="streams"
                         style={{margin: 0, borderRadius: 2, maxHeight: isDesktop ? 500 : 400}}/>
                </Box>
            </Grid>
            <Grid item xs={12} sm={5} md={5}>
                <Box
                    sx={{background: gridColor ? 'red' : '', display: 'flex', pb: 3}}
                    justifyContent={isMobile ? 'center' : 'flex-start'}
                    alignItems={'center'}
                >
                    <WatchtowerFeatureText
                        title={title}
                        description={description}
                        textAlign={isMobile ? 'center' : 'left'}
                    />
                </Box>
            </Grid>
        </Grid>
    );
}

export const DevFriendly: FC<{
    // isTablet: boolean,
    // isDesktop: boolean,
}> = ({}) => {
    const isDesktop = useMediaQuery('(min-width:1024px)');
    const isTablet = useMediaQuery('(min-width:480px)');
    const isMobile = !isDesktop && !isTablet
    const {title, description, imgSrc} = devSectionData
    return (
        <Grid
            container
            sx={{minHeight: isDesktop ? 500 : 300, background: gridColor ? 'yellow' : '', mt: isMobile ? 9 :0, position: 'relative', zIndex: 10}}
            direction={'row-reverse'}
            alignItems={'center'}
            justifyContent={'center'}
            spacing={3}
        >
            <Grid item xs={12} sm={6} md={7}>
                <Box
                    sx={{background: gridColor ? 'red' : '', display: 'flex', pb: !isMobile ? 3 : 0}}
                    justifyContent={isMobile ? 'center' : 'flex-start'}
                    alignItems={'center'}
                >
                    <img src={imgSrc} alt="streams"
                         style={{margin: 0, borderRadius: 2, maxHeight: isDesktop ? 450 : 350}}/>
                </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={5}>
                <Box
                    sx={{background: gridColor ? 'red' : '', display: 'flex', pb: 3}}
                    justifyContent={isMobile ? 'center' : 'flex-end'}
                    alignItems={'center'}
                >
                    <WatchtowerFeatureText
                        title={title}
                        description={description}
                        textAlign={isMobile ? 'center' : 'right'}
                    />
                </Box>
            </Grid>
        </Grid>
    );
}


export function DesktopWatchtowerOLD() {
    return (
        <Container sx={{mb: 6, position: 'relative', top: 0}}>
            <Box sx={{textAlign: 'center', mb: 15}}>
                <WatchtowerIcon sx={{fontSize: 120, color: '#444'}}/>
                <Typography variant={'h1'} sx={{mt: 1, letterSpacing: 1}}>WATCHTOWER</Typography>
            </Box>
            <Stack direction={'row-reverse'} alignItems={'center'} justifyContent={'center'} spacing={3}>
                <Box>
                    <div style={{
                        borderRadius: 20,
                        width: 536,
                        background: '#424141',
                        boxShadow: '50px 50px 50px rgba(0, 0, 0, 0.45)',
                        zIndex: 10,
                        position: 'relative'
                    }}>
                        <img src="/img/ws_topo_dashboard_rd_preview.jpg" alt="dashboard" width={512}
                             style={{margin: 12, borderRadius: 2}}/>
                    </div>
                </Box>
                <Box sx={{maxWidth: 300, textAlign: 'right'}}>
                    <Typography variant={'h3'} sx={{mb: 1}}>Realtime wildlife event map</Typography>
                    <Typography sx={{fontSize: 14, lineHeight: 1.3}}>
                        Make critical decisions and take quick action
                        to preserve wildlife using realtime data from ranger reports,
                        community reports, cameras, drones data, and various other realtime sensors in the field.
                        {/*WATCHTOWER is a wildlife*/}
                        {/*reserve data dashboard to empower wildlife stewards with the critical data they need to*/}
                        {/*make the best decisions and implement the most effective interventions. Built from the*/}
                        {/*ground up with an open api, any programmable sensor, drone, camera, or mobile*/}
                        {/*application can send structured data to topo.*/}
                    </Typography>
                </Box>
            </Stack>
        </Container>
    );
}
