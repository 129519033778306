import React, {FC} from 'react';
import {CardTemplate} from "./CardTemplate";

export const DroneHerdContent: FC<{}> = ({}) => {
    return (
        <CardTemplate
            title={"Herd health metrics"}
            imgSrc={"/img/cards/herd.png"}
            iconSrc={"/img/icons/drone.svg"}
            categoryTag={"Cervus canadensis"}
            tagColor={"#3D8283"}
            timestamp={"2022-05-29T20:14:38Z"}
        />
    )
}
