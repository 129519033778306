import React, {useCallback, useState} from 'react';
import {DesktopHero, MobileHero, TabletHero} from "./00_Hero";
import {Button, Container, TextField, Typography, useMediaQuery} from "@mui/material";
import {WatchtowerSection} from "./01_Watchtower";

export function LandingPage() {
    const isDesktop = useMediaQuery('(min-width:1024px)');
    const isTablet = useMediaQuery('(min-width:480px)');
    return (
        <main style={{width: '100%', overflowX: 'hidden',}}>
            {isDesktop ? <DesktopHero/> : isTablet ? <TabletHero/> : <MobileHero/>}
            <WatchtowerSection/>
            <ContactSection/>
        </main>
    );
}

const useInputState = (defaultValue?: string | undefined):
    [string, React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>] => {
    const [value, setValue] = useState(defaultValue || '')
    const inputChange = useTextInputChange(setValue)
    return [value, inputChange]
}

const useTextInputChange = (setValue: (newValue: string) => void):
    React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> => {
    return useCallback((e) => setValue(e.target.value), [setValue])
}

export function ContactSection() {
    const [email, setEmail] = useState('')
    const emailTextChange = useTextInputChange(setEmail)
    const [name, setName] = useState('')
    const nameTextChange = useTextInputChange(setName)
    const [message, setMessage] = useState('')
    const messageTextChange = useTextInputChange(setMessage)

    const onSubmit = useCallback(() => {
        console.log("submit with:", email, name, message)
        setEmail('')
        setName('')
        setMessage('')
    }, [email, name, message])

    return (
        <Container maxWidth={'sm'} sx={{mb: 15, mt: 10, position: 'relative'}}>
            <Typography variant={'h1'} sx={{my: 2}}>
                Get in touch
            </Typography>
            <TextField
                fullWidth
                required
                color={'secondary'}
                variant="filled"
                label="Email"
                type={'email'}
                sx={{my: 1}}
                value={email}
                onChange={emailTextChange}
            />
            <TextField
                fullWidth
                required
                color={'secondary'}
                variant="filled"
                label="Name"
                sx={{my: 1}}
                value={name}
                onChange={nameTextChange}
            />
            <TextField
                fullWidth
                multiline
                color={'secondary'}
                rows={4}
                variant="filled"
                label="Message"
                sx={{my: 1}}
                value={message}
                onChange={messageTextChange}
            />
            <Button fullWidth variant={'contained'} color={'secondary'} sx={{mt: 1}} onClick={onSubmit}>
                Submit
            </Button>
        </Container>
    );
}
