import React, {FC} from 'react';
import {CardTemplate} from "./CardTemplate";

export const LoggingContent: FC<{}> = ({}) => {
    return (
        <CardTemplate
            title={"Community Report"}
            imgSrc={"/img/cards/logging.png"}
            iconSrc={"/img/icons/device.svg"}
            categoryTag={"Illegal logging"}
            tagColor={"#FB5C2A"}
            timestamp={"2022-05-29T20:14:38Z"}
        />
    )
}
