import React, {FC} from 'react';
import {CardTemplate} from "./CardTemplate";

export const GunshotContent: FC<{}> = ({}) => {
    return (
        <CardTemplate
            title={"Possible firearm discharge detection"}
            imgSrc={"/img/cards/sound_wave.png"}
            iconSrc={"/img/icons/audio.svg"}
            categoryTag={"Illegal Hunting"}
            tagColor={"#FB5C2A"}
            timestamp={"a few moments ago"}
        />
    )
}
