import React, {FC} from 'react';
import {Box, Typography} from "@mui/material"

export const WatchtowerFeatureText: FC<{
    title: string,
    description: string,
    textAlign: "left" | "right" | "center",
}> = ({title, description, textAlign}) => {
    return (
        <Box sx={{maxWidth: 300, textAlign}}>
            <Typography variant={'h3'} sx={{mb: 1}}>{title}</Typography>
            <Typography sx={{fontSize: 14, lineHeight: 1.3}}>{description}</Typography>
        </Box>
    );
}
