import React from 'react';
import './beacon.css';
import {TopoPoint} from "./TopoPoint";
import {Typography, useMediaQuery, useTheme} from "@mui/material";

import {DroneHerdContent} from "./cards/DroneHerdContent";
import {EndangeredSpeciesContent} from "./cards/EndageredSpeciesContent";
import {HighTempContent} from "./cards/HIghTempContent";
import {GunshotContent} from "./cards/GunshotContent";
import {LoggingContent} from "./cards/LoggingContent";
import PawIcon from '@mui/icons-material/Pets';
import DangerIcon from '@mui/icons-material/GppMaybe';
import WarningIcon from '@mui/icons-material/Dangerous';
import TempIcon from '@mui/icons-material/Thermostat';

{/*<h1>Wildlife Insights. Wildlife Protection.</h1>*/
}
{/*<h1>Community Driven Conservation Intelligence</h1>*/
}
const copy = {
    title: "Open Source Conservation Intelligence",
    description: "Illegal poaching, wildfires, drought, habitat loss, human activity pollution and land" +
        " encroachment all threaten the survival of many species around the world. Wildshield builds" +
        " tools to empower" +
        " wildlife stewards to remain two steps ahead of any potential threat to the wildlife" +
        " and wild lands that they protect."
}

export const MobileHero = () => {
    return (
        <div
            className={'notouch'}
            style={{backgroundColor: '#f2f2f2', height: '100vh', overflowX: 'hidden'}}>
            <div style={{position: 'absolute', zIndex: 10}}>
                <img src="/img/ws_logo_sm.png" alt="logo" style={{width: 60, height: 60, margin: '10px 10px 0'}}/>
                <div style={{
                    maxWidth: 500,
                    margin: '20px 15px',
                    fontSize: 12,
                    color: '#333',
                    position: 'relative',
                    // background: 'yellow',
                }}>
                    <Typography variant={'h1'}>{copy.title}</Typography>
                    <Typography variant={'body1'} sx={{maxWidth: 250, mt: 1}}>{copy.description}</Typography>
                </div>
            </div>

            <div
                style={{
                    height: '90vh',
                    // background: 'gold',
                    position: 'absolute',
                    width: '100vw',
                    // zIndex: 100,
                    top: '10vh',
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <div
                    className={'notouch'}
                    style={{
                        position: 'relative',
                        backgroundImage: 'url("/img/hero_topo.png")',
                        backgroundSize: '250%',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: '37% 100%',
                        width: '100vw',
                        height: '180vw',
                        // maxWidth: '90vw',
                        // right: 0,
                        // bottom: -20,
                    }}>


                    <TopoPoint isMobile={true} id={"C"} left={28} top={64} card={<DroneHerdContent/>}
                               icon={<PawIcon/>}/>
                    <TopoPoint isMobile={true} id={"F"} left={50} top={78} card={<EndangeredSpeciesContent/>}
                               icon={<PawIcon/>}/>
                    <TopoPoint isMobile={true} id={"H"} left={62} top={50} card={<HighTempContent/>}
                               icon={<TempIcon/>}/>
                    <TopoPoint isMobile={true} id={"J"} left={85} top={30} card={<LoggingContent/>}
                               icon={<WarningIcon/>}/>
                    <TopoPoint isMobile={true} id={"K"} left={78} top={65} card={<GunshotContent/>} icon={<DangerIcon/>}
                               beaconActive={true}/>
                </div>
            </div>
        </div>
    );
}

export function TabletHero() {
    return (
        <div style={{
            backgroundColor: '#f2f2f2',
            userSelect: 'none',
            height: '70vh',
            overflowX: 'hidden'
        }}>

            <div style={{position: 'absolute', zIndex: 10}}>
                <img src="/img/ws_logo_sm.png" alt="logo" style={{width: 60, height: 60, margin: '15px 20px 0'}}/>
                <div style={{
                    width: '55%',
                    maxWidth: '680px',
                    margin: '30px 25px',
                    fontSize: 12,
                    color: '#333',
                    position: 'relative',
                    // background: 'yellow',
                }}>
                    <Typography variant={'h1'}>{copy.title}</Typography>
                    <Typography variant={'body1'} sx={{mt:1}}>{copy.description}</Typography>
                </div>
            </div>

            <div
                className={'notouch'}
                style={{
                    position: 'absolute',
                    backgroundImage: 'url("/img/hero_topo.png")',
                    backgroundSize: '150%',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: '40% 100%',
                    width: '100vw',
                    height: '100vw',
                    // maxWidth: '90vw',
                    right: 0,
                    // top: 100,
                }}>

                <TopoPoint id={"C"} left={30} top={62} card={<DroneHerdContent/>} icon={<PawIcon/>}/>
                <TopoPoint id={"F"} left={50} top={75} card={<EndangeredSpeciesContent/>} icon={<PawIcon/>}/>
                <TopoPoint id={"H"} left={65} top={48} card={<HighTempContent/>} icon={<TempIcon/>}/>
                <TopoPoint id={"J"} left={80} top={30} card={<LoggingContent/>} icon={<WarningIcon/>}/>
                <TopoPoint id={"K"} left={90} top={60} card={<GunshotContent/>} icon={<DangerIcon/>}
                           beaconActive={true}/>
            </div>
        </div>
    );
}

export function DesktopHero() {
    return (
        <div style={{
            backgroundColor: '#f2f2f2',
            height: '100vh',
            overflowX: 'hidden'
        }}>

            <div style={{position: 'absolute', zIndex: 10}}>
                <img src="/img/ws_logo_sm.png" alt="logo" style={{width: 60, height: 60, margin: '20px 25px 0'}}/>
                <div style={{
                    width: '60%',
                    maxWidth: '700px',
                    margin: '40px 35px',
                    fontSize: 12,
                    color: '#333',
                    position: 'relative',
                    // background: 'yellow',
                }}>
                    <Typography variant={'h1'}>{copy.title}</Typography>
                    <Typography variant={'body1'} sx={{mt:1}}>{copy.description}</Typography>
                </div>
            </div>

            <div style={{
                position: 'absolute',
                backgroundImage: 'url("/img/hero_topo.png")',
                backgroundSize: '110%',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: '0% 100%',
                height: '100vh',
                width: 'calc(100vh * 1.5)',
                // maxWidth: '90vw',
                right: 0,
            }}>

                <TopoPoint id={"C"} left={40} top={55} card={<DroneHerdContent/>} icon={<PawIcon/>}/>
                <TopoPoint id={"F"} left={65} top={65} card={<EndangeredSpeciesContent/>} icon={<PawIcon/>}/>
                <TopoPoint id={"H"} left={60} top={24} card={<HighTempContent/>} icon={<TempIcon/>}/>
                <TopoPoint id={"J"} left={80} top={20} card={<LoggingContent/>} icon={<WarningIcon/>}/>
                <TopoPoint id={"K"} left={85} top={50} card={<GunshotContent/>} icon={<DangerIcon/>}
                           beaconActive={true}/>
            </div>
        </div>
    );
}
