import React, {FC} from 'react';
import {CardTemplate} from "./CardTemplate";

export const HighTempContent: FC<{}> = ({}) => {
    return (
        <CardTemplate
            title={"Temperature/Humidity threshold notice"}
            imgSrc={"/img/cards/temp.png"}
            iconSrc={"/img/icons/temp.svg"}
            categoryTag={"Fire Danger"}
            tagColor={"#FB5C2A"}
            timestamp={"2022-05-29T20:14:38Z"}
        />
    )
}
