import React from 'react';
import {SvgIcon} from "@mui/material";
import {SxProps} from "@mui/system";
import {Theme} from "@mui/material/styles";

export function WatchtowerIcon({sx}: { sx?: SxProps<Theme> }) {
    return (
        <SvgIcon sx={sx} viewBox={"0 0 326.77 508.43"}>
            <path
                d="M158.59,469.21h10v-.83l35.68-74.08,48.4,74.92h9.23l-43.71-268h63.4v-61h-64v-35h36.9L163.1,38.62,74.49,105.21h35.1v35H45.59v61h61.82l-42.13,268h9.11v-.09l48.65-74.72m10.75-148.34l-17.86-44.16,.11-.68h41.55v3.03l-23.8,41.81Zm23.8-29.38l.17,89.06-21.13-52.25,20.96-36.82Zm.4,104.53h-59.81l34.96-61.42,24.84,61.42Zm-81.51,133.66l18.43-117.24,24.85,50.77-43.28,66.48Zm21.66-124.66h59.28l-33.84,51.98-25.44-51.98Zm28.97,58.61l31.49-48.36,.84,114.41-32.33-66.06Zm40.48-187.61h41.21l.12,.75-17.07,43.8-24.26-42.76v-1.8Zm44-34v16h-14v-16h14Zm-14-4v-19h14v19h-14Zm-30.18,52.35l21.43,37.77-21.25,54.52-.18-92.29Zm.18,239.35l-.22-115.93,32.27,49.71-32.04,66.22Zm82.54,.11l-43.34-66.77,24.2-50.02,19.13,116.78Zm-47.15-73l-33.63-51.82h58.71l-25.07,51.82Zm-34.29-60.82l23.97-61.51,34.9,61.51h-58.87Zm57.06-15l-30.28-53.37,15.16-38.9,15.12,92.27Zm28.84-162h23v19h-23v-19Zm0,23h23v18h-23v-18Zm-37-23h33v19h-33v-19Zm0,23h33v18h-33v-18Zm-21-62h15v35h-15v-35Zm-59,0h53v34h-53v-34Zm-22,0h16v35h-16v-35Zm0,39h16v19h-16v-19Zm0,23h16v16h-16v-16Zm-1.2,46.55l15.89,39.29-30.49,53.56,14.6-92.85Zm-38.8-69.55h34v19h-34v-19Zm-4,41h-22v-18h22v18Zm0-22h-22v-19h22v19Zm4,22v-18h34v18h-34Z"/>
        </SvgIcon>
    )
}
