import React, {FC, useCallback} from 'react';
import './beacon-point.css';
import {Box, Paper} from "@mui/material";
import {CardPopper} from "./cards/CardPopper";



export const TopoPoint: FC<{
    id: string,
    icon: React.ReactNode,
    card: React.ReactNode,
    top: number,
    left: number,
    isMobile?: boolean,
    beaconActive?: boolean,
}> = ({id, top, left, isMobile, beaconActive, icon, card}) => {

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const hasMouseSupport = !window.matchMedia("(any-hover: none)").matches

    const openPopper = useCallback((event: React.MouseEvent<HTMLElement> | React.TouchEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
        event.stopPropagation()
    }, [setAnchorEl])
    const closePopper = useCallback(() => {
        setAnchorEl(null)
    }, [setAnchorEl])

    const PointIcon = icon

    return (
        <div onMouseOut={hasMouseSupport ? closePopper : undefined}>
            <Paper
                elevation={3}
                className={beaconActive ? "beacon-point" : "beacon-point-grey"}
                style={{
                    userSelect: 'none',
                    position: 'absolute',
                    top: `${top}%`,
                    left: `${left}%`,
                    height: 20,
                    width: 20,
                    borderRadius: 20,
                    background: beaconActive ? '#f13d3d' : '#444',
                    // background: disabled ? '#777' : '#444',
                    color: '#fff',
                    textAlign: 'center',
                    padding: 5,
                    fontSize: 15,
                    cursor: 'pointer',
                    zIndex: 10,
                }}
                onMouseOver={hasMouseSupport ? openPopper : undefined}
                onTouchStart={openPopper}
                onTouchEnd={closePopper}
            >
                <Box style={{margin: 2, zIndex: 11, position: 'relative',
                    // background: 'blue',
                    fontSize:16, height: 20, width: 20, top: -1, left: -2}}>
                    {icon}
                </Box>
            </Paper>
            <CardPopper card={card} anchorEl={anchorEl} isMobile={isMobile}/>
        </div>
    )
}
